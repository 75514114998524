@import

//Général
"Country/home/home.scss",

//Admin 
"Admin/GaCmsBlockHomePage.scss",
"Admin/DepartmentZone.scss",
"Admin/ShippingFees.scss",
"Admin/Stats.scss",

//partials GA
"GA/partials/header.scss",
"GA/partials/navGA.scss",
"GA/partials/footer.scss",


//partials Country
"Country/partials/header.scss",
"Country/partials/navCountry.scss",
"Country/partials/footer.scss";

@import '~select2/dist/css/select2.min.css';
