@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.header-stats {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 60px;

  .export-button-stats {
    width: 100%;
    margin-top: 20px;
  }

  .stats {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    .stats1 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 160px;
      width: 160px;
      border-radius: 50%;
      border: 4px solid red;

      h2 {
        font: normal normal 700 22px Jost;
        letter-spacing: 0px;
        text-transform: uppercase;
      }

      h3 {
        font: normal normal 700 14px Jost;
        letter-spacing: 0px;
        text-transform: uppercase;
      }
    }

    .stats2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 160px;
      width: 160px;
      border-radius: 50%;
      border: 4px solid green;

      h2 {
        font: normal normal 700 22px Jost;
        letter-spacing: 0px;
        text-transform: uppercase;
      }

      h3 {
        font: normal normal 700 14px Jost;
        letter-spacing: 0px;
        text-transform: uppercase;
      }
    }

    .stats3 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 160px;
      width: 160px;
      border-radius: 50%;
      border: 4px solid blue;

      h2 {
        font: normal normal 700 22px Jost;
        letter-spacing: 0px;
        text-transform: uppercase;
      }

      h3 {
        font: normal normal 700 14px Jost;
        letter-spacing: 0px;
        text-transform: uppercase;
      }
    }
  }
}

.container-stats {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

  .item-stats {
    margin-bottom: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px #00000029;
    width: 500px;

    @media (max-width: 500px) {
      width: 350px;
    }

    .title-stats {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      padding: 15px;
      border-bottom: 1px solid #8080806e;
      align-items: center;
      height: 110px;
      gap: 10px;

      h4 {
        font: normal normal 600 20px Jost;
        margin: 0;
      }
    }

    .content-stats {
      padding: 15px;
      background-color: #f7f7f7;
      height: 350px;
      overflow-y: scroll;
    }

    span {
      font: normal normal 500 14px Poppins;
    }

    hr {
      border: 0.5px #f7f7f7;
      margin: 12px 0px;
    }

    p {
      font: normal normal 600 16px Poppins;
    }

    form {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      gap: 5px;
      margin-bottom: 20px;
    }

    label {
      margin-right: 3px;
      margin-bottom: 5px;
    }

    li {
      list-style-type: none;
      font: normal normal 500 14px Poppins;
      margin-bottom: 3px;
    }

    ul {
      padding: 0px;
    }

    // input {
    //   background-color: aqua;
    // }
  }
}