@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.navGA {
    background-color: #096264;
    position: relative;

    .navGA-content {
        display: flex;
        justify-content: center;
        align-items: center;

        .container {
            margin-left: 0;
            margin-right: 0;
        }

        .left-item {
            position: relative;
            margin-top: 5px;
            margin-bottom: 5px;

            .triangle {
                position: absolute;
                top: 0;
                left: 0;
                width: 0;
                height: 0;
                border-width: 50px 15px 0px 0;
                border-color: transparent #075254 transparent transparent;
                border-style: solid;
            }

            .item-prio {
                background: #075254 0% 0% no-repeat padding-box;
                height: 50px;
                display: flex;
                align-items: center;
                margin-left: 15px;

                a {
                    color: white;
                    text-align: left;
                    font: normal normal 500 13px Poppins;
                    letter-spacing: 0px;
                    text-transform: uppercase;
                    text-decoration: none;
                    margin-right: 7px;
                }
            }

            a:hover {
                border-bottom: 2px solid white;
            }
        }

        .subcategories-prio {
            display: none;
            position: absolute;
            background-color: #FFFFFF;
            margin-left: 10%;
            margin-right: 10%;
            width: 80%;
            left: 0;
            top: 90%;
            height: 400px;
            overflow-y: scroll;
            border-radius: 2px;
            box-shadow: 0px 0px 10px #00000029;



            .subcategory {
                display: flex;
                justify-content: start;
                gap: 20px;
                width: auto;
                flex-wrap: wrap;
                padding: 30px 5%;
                margin-bottom: 10px;


                .family {
                    display: flex;
                    flex-direction: column;
                    width: 230px;
                    margin-bottom: 5px;


                    a {
                        color: #F94A4A;
                        font: normal normal 600 14px Poppins;
                        letter-spacing: 0px;
                        text-transform: uppercase;
                        text-decoration: none;
                    }

                    a:hover {
                        border-bottom: none;
                        background-color: #00000011;
                        border-radius: 5px;
                    }

                    .sub-subcategory {
                        display: flex;
                        flex-direction: column;

                        a {
                            color: #096264;

                            font: normal normal 500 13px Jost;
                            letter-spacing: 0px;
                            text-transform: none;
                            text-decoration: none;
                            padding: 2px 5px;
                        }

                        .sub-sub-category.hidden {
                            display: none;
                        }

                        .sub-sub-category {
                            display: flex;
                            flex-direction: column;

                            a {
                                color: #858383;
                                font: italic 500 13px Jost;
                                letter-spacing: 0px;
                                text-transform: none;
                                text-decoration: none;
                                padding: 2px 0px;
                                padding-left: 25px;
                            }

                            a:hover {
                                border-bottom: none;
                                background-color: #00000005;
                                border-radius: 5px;
                            }
                        }
                    }
                }
            }
        }

        .right-item {
            display: flex;
            align-items: center;

            .point {
                width: 5px;
                height: 5px;
                background-color: white;
                border-radius: 50%;
                margin: 0px 15px;
            }

            .item {
                display: flex;
                align-items: center;
                height: 60px;

                a {
                    color: white;
                    text-align: left;
                    font: normal normal 500 13px Poppins;
                    letter-spacing: 0px;
                    text-transform: uppercase;
                    text-decoration: none;
                }

                .subcategories {
                    display: none;
                    position: absolute;
                    background-color: #FFFFFF;
                    margin-left: 10%;
                    margin-right: 10%;
                    width: 80%;
                    left: 0;
                    top: 90%;
                    height: 400px;
                    overflow-y: scroll;
                    border-radius: 2px;
                    box-shadow: 0px 0px 10px #00000029;



                    .subcategory {
                        display: flex;
                        justify-content: start;
                        gap: 20px;
                        width: auto;
                        flex-wrap: wrap;
                        padding: 30px 5%;
                        margin-bottom: 10px;


                        .family {
                            display: flex;
                            flex-direction: column;
                            width: 230px;
                            margin-bottom: 5px;


                            a {
                                color: #F94A4A;
                                font: normal normal 600 14px Poppins;
                                letter-spacing: 0px;
                                text-transform: uppercase;
                                text-decoration: none;
                            }

                            a:hover {
                                border-bottom: none;
                                background-color: #00000011;
                                border-radius: 5px;
                            }

                            .sub-subcategory {
                                display: flex;
                                flex-direction: column;

                                a {
                                    color: #096264;
                                    font: normal normal 500 13px Jost;
                                    letter-spacing: 0px;
                                    text-transform: none;
                                    text-decoration: none;
                                    padding: 2px 5px;
                                }

                                .sub-sub-category.hidden {
                                    display: none;
                                }

                                .sub-sub-category {
                                    display: flex;
                                    flex-direction: column;
        
                                    a {
                                        color: #858383;
                                        font: italic 500 13px Jost;
                                        letter-spacing: 0px;
                                        text-transform: none;
                                        text-decoration: none;
                                        padding: 2px 0px;
                                        padding-left: 25px;
                                    }
        
                                    a:hover {
                                        border-bottom: none;
                                        background-color: #00000005;
                                        border-radius: 5px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            a:hover {
                border-bottom: 2px solid white;
            }

            .btn-deposits {
                padding-bottom: 2px;

                a {
                    border: none;
                    border-radius: 5px;
                    //background-color: #075254;
                    background-color: #f94a4a;
                    color: #FFFFFF;
                    font: normal normal 500 13px Poppins;
                    letter-spacing: 0px;
                    text-transform: uppercase;
                    cursor: pointer;
                    padding: 5px 10px;
                    text-decoration: none;
                }

                a:hover {
                    background-color: #F94A4A;
                }
            }
        }
    }
}

#burger {
    display: none;
}

#navbarMobile {
    display: none;
}

.navGA-responsive {
    display: none;
}

@media(max-width: 1100px) {

    .navGA {
        display: none;
    }

    .navGA-responsive {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #096264;

        #navbar {
            display: none;
        }

        .right-item {
            margin-right: 15px;
        }

        #burger {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px;
            color: white;
            cursor: pointer;
            font-size: 26px;
            margin-left: 30px;
        }

        #navbarMobile {
            position: absolute;
            z-index: 10;
            left: 0px;
            background-color: #096264;
            border-radius: 15px;
            width: auto;
            min-width: 250px;
            max-height: 500px;
            margin-top: 5px;
            padding: 10px 30px;
            overflow: scroll;

            #ulNavbarMobile {
                margin-bottom: 0px;
                margin-top: 0px;
                padding: 0px;

                .item-menu-mobile {

                    .sub-categorie,
                    .sub-sub-categories {
                        display: none;
                    }

                    .categorie {
                        a {
                            color: #FFFFFF;
                            text-decoration: none;
                            font: normal normal 500 15px Jost;
                            letter-spacing: 0px;
                            text-transform: uppercase;
                        }

                        button {
                            appearance: none;
                            border: none;
                            background: none;
                            padding: 0;
                            margin: 0;
                            cursor: pointer;
                            outline: none;
                            text-decoration: none;

                            img {
                                width: 10px;
                                height: 10px;
                                margin-left: 5px;
                            }

                            .arrow {
                                transition: transform 0.3s ease;
                                transform: rotate(90deg);
                            }

                            .arrow-down {
                                transform: rotate(-90deg);
                            }
                        }
                    }

                    .sub-categorie {
                        a {
                            color: #FFFFFF;
                            text-decoration: none;
                            font: normal normal 400 14px Poppins;
                            letter-spacing: 0px;
                            text-transform: uppercase;
                        }


                        button {
                            appearance: none;
                            border: none;
                            background: none;
                            padding: 0;
                            margin: 0;
                            cursor: pointer;
                            outline: none;
                            text-decoration: none;

                            img {
                                width: 10px;
                                height: 10px;
                                margin-left: 5px;
                            }

                            .arrow {
                                transition: transform 0.3s ease;
                                transform: rotate(90deg);
                            }

                            .arrow-down {
                                transform: rotate(-90deg);
                            }
                        }
                    }

                    .sub-sub-categories {
                        a {
                            color: #FFFFFF;
                            text-decoration: none;
                            font: normal normal 400 12px Poppins;
                            font-style: italic;
                            letter-spacing: 0px;
                            text-transform: uppercase;
                        }


                        button {
                            appearance: none;
                            border: none;
                            background: none;
                            padding: 0;
                            margin: 0;
                            cursor: pointer;
                            outline: none;
                            text-decoration: none;

                            img {
                                width: 10px;
                                height: 10px;
                                margin-left: 5px;
                            }

                            .arrow {
                                transition: transform 0.3s ease;
                                transform: rotate(90deg);
                            }

                            .arrow-down {
                                transform: rotate(-90deg);
                            }
                        }
                    }

                    .sub-subcategories {
                        flex-direction: column;

                        a {
                            color: #FFFFFF;
                            text-decoration: none;
                            font: italic 400 11px Poppins;
                            letter-spacing: 0px;
                            text-transform: uppercase;
                        }


                        button {
                            appearance: none;
                            border: none;
                            background: none;
                            padding: 0;
                            margin: 0;
                            cursor: pointer;
                            outline: none;
                            text-decoration: none;

                            img {
                                width: 10px;
                                height: 10px;
                                margin-left: 5px;
                            }

                            .arrow {
                                transition: transform 0.3s ease;
                                transform: rotate(90deg);
                            }

                            .arrow-down {
                                transform: rotate(-90deg);
                            }
                        }
                    }

                    margin: 5px 0px;
                    font-size: 15px;
                    font-weight: bold;
                }

                list-style-type: none;

                #dropdown-content-mobile {
                    display: none;
                }

                img {
                    width: 60%;
                }
            }

            .btn-responsive {
                display: flex;
                align-items: center;
                gap: 10px;

                .btn-deposits {
                    margin-bottom: 10px;
                    margin-top: 10px;
    
                    a {
                        border: none;
                        border-radius: 5px;
                        //background-color: #075254;
                        background-color: #f94a4a;
                        color: #FFFFFF;
                        font: normal normal 500 13px Poppins;
                        cursor: pointer;
                        padding: 5px 10px;
                        text-decoration: none;
                        text-transform: uppercase;
                    }
    
                    a:hover {
                        background-color: #F94A4A;
                    }
                }
    
                .btn-compte {
                    padding-bottom: 2px;
    
                    a {
                        border: none;
                        border-radius: 5px;
                        //background-color: #075254;
                        background-color: #858383;
                        color: #FFFFFF;
                        font: normal normal 500 13px Poppins;
                        letter-spacing: 0px;
                        text-transform: uppercase;
                        cursor: pointer;
                        padding: 5px 10px;
                        text-decoration: none;
                    }
    
                    a:hover {
                        background-color: #777777;
                    }
                }
            }
        }
    }
}