#franco > td, #discount > td, #fees > td{
  text-align: center;
}

button.btn-new {
  float: right;
}

button.btn-remove {
  float: right;
  margin-right: 18px;
}

div.block-element{
  margin-left: 44px;
}

ul.fees-condition-element{
  list-style-type: none;
  margin-right: 15px;
  margin-left: -18px;
}

li.header{
  border-bottom: 2px solid black;
  border-left: 0.5px solid grey;
}

select.fees-type-select{
  margin-left: 4px;
}

.btn-new{
  margin-right: -20px;
}

.fees-btn-remove.btn-remove{
  margin-right: 12px;
}

#container-general-fees{
  padding-top: 40px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

#shipping_fees_form_submit{
  margin-right: -40px;
}

div.fees-restriction-content{
  div.alert-danger{
    ul{
      li{
        font-size: 10px;
      }
    }
  }
}