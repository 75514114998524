@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.cke_notification_warning {
    display: none;
}

.container_admin{
    margin: 0%;
    .promotion_admin {
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-bottom: 60px;

        .promotion_description_admin {
            width: 100%;
            height: 450px;

            .with_picture {
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
                background-size: 100%;
            }
            .without_picture {
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 100%;
            }
        }

        .promotion1_admin {
            width: 75%;
            margin: 0 auto;
            /* Centrer horizontalement */

            img {
                object-fit: cover;
                object-position: left center;
                max-width: 100%;
                height: 100%;
                border-radius: 50px;
            }
        }

        .promotion2_admin {
            width: 35%;
            margin: 0 auto;
            /* Centrer horizontalement */

            img {
                object-fit: cover;
                object-position: bottom center;
                max-width: 100%;
                height: 100%;
                border-radius: 50px;
            }
        }
    }
}

#picture_slider_form_cacompany label,
#popup_form_cacompany label {
    margin-right: 10px; /* Ou une autre valeur pour espacer les labels */
}

#picture_slider_form_cacompany input,
#popup_form_cacompany input {
    margin-right: 5px; /* Ou une autre valeur pour espacer les labels */
}

#ga_cms_block_home_page_form_cacompany label
{
    margin-right: 10px;
}

#ga_cms_block_home_page_form_cacompany input
{
    margin-right: 5px;
}