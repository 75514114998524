@import url('https://fonts.googleapis.com/css2?family=Jost:wght@900&family=Poppins:wght@400;600;700;900&display=swap');

.footer-country {

    hr {
        border: #915832 1px solid;
        border-radius: 5px;
        width: 45%;
    }


    #sectionAssurance {
        background-color: #064C37;
        margin: 0px;

        .assurance {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 50px;
            margin-left: 10%;
            margin-right: 10%;

            .itemAssurance {
                display: flex;
                flex-direction: column;
                align-items: center;
                text-align: center;
                color: white;
                padding-top: 25px;
                padding-bottom: 25px;

                img {
                    height: 30px;
                    width: 30px;
                }

                .itemAssurance-icon {
                    align-items: center;
                    font-size: 30px;
                }

                p {
                    color: var(--unnamed-color-ffffff);
                    text-align: center;
                    font: normal normal normal 16px Poppins;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    text-shadow: 0px 3px 6px #00000029;
                    margin-bottom: 0;
                    margin-top: 5px;
                }
            }
        }

        @media screen and (max-width: 768px) {
            .assurance {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 5px 30px;

                .itemAssurance {
                    padding-top: 10px;
                    padding-bottom: 10px;

                    p {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    #sectionFooter {
        background-color: #02271C;

        #footer {
            margin-left: 10%;
            margin-right: 10%;
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 50px;
        }

        #itemLogo {
            padding-top: 45px;
            margin-bottom: 25px;

            img {
                height: 100px;
                width: 100px;
            }

            .item-footer-spacing {
                display: flex;
                flex-direction: column;
                gap: 15px;

                p {
                    text-align: left;
                    font: normal normal normal 14px Poppins;
                    letter-spacing: 0px;
                }
            }
        }

        .itemFooter {
            color: white;
            padding-top: 70px;
            padding-bottom: 50px;

            #logofooterCountry {
                height: 120px;
                width: 180px;
                margin-bottom: 15px;
            }

            .titleFooter {
                color: var(--unnamed-color-ffffff);
                text-align: left;
                font: normal normal 700 20px/30px Poppins;
                letter-spacing: 2px;
                color: #FFFFFF;
                text-transform: uppercase;
                margin-bottom: 15px;
            }

            .follow {
                text-align: center;
            }

            a {
                color: white;
                text-decoration: none;
                cursor: pointer;
                margin: 0;
                text-align: left;
                font: normal normal 400 13px Poppins;
                letter-spacing: 1.6px;
            }

            p {
                margin: 0;
                text-align: left;
                font: normal normal 400 13px Poppins;
                letter-spacing: 1.6px;
            }

            .reseaux {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 20px;

                img {
                    height: 35px;
                    width: 35px;
                }
            }
        }

        .itemConfidentiality {
            text-align: center;
            color: white;
            margin-top: 20px;
            padding: 10px;
            font: normal normal 400 13px Poppins;
        }

        @media screen and (max-width: 768px) {
            #footer {
                grid-template-columns: repeat(2, 1fr); // Affiche en colonne par 2 éléments sur les petits écrans
                grid-gap: 10px 30px;

                .itemFooter {
                    color: white;
                    padding-top: 10px;
                    padding-bottom: 10px;

                }

                #logofooterCountry {
                    height: 120px;
                    width: 150px;
                    margin-bottom: 15px;
                }
            }
        }

        @media screen and (max-width: 400px) {
            #footer {
                grid-template-columns: repeat(2, 1fr); // Affiche en colonne par 2 éléments sur les petits écrans

                .itemFooter {
                    color: white;
                    padding-top: 10px;
                    padding-bottom: 10px;


                    .titleFooter {
                        color: var(--unnamed-color-ffffff);
                        text-align: left;
                        font: normal normal 700 18PX Poppins;
                        letter-spacing: 2px;
                        color: #FFFFFF;
                        text-transform: uppercase;
                        margin-bottom: 15px;
                    }

                    p {
                        margin: 6px 0px;
                        text-align: left;
                        font: normal normal 400 11px Poppins;
                        letter-spacing: 1.6px;
                    }

                    .reseaux {
                        display: flex;
                        align-items: center;
                        gap: 15px;

                        img {
                            height: 30px;
                            width: 30px;
                        }
                    }
                }
            }
        }
    }

    #sectionSubFooter {
        background-color: #000000;

        #subFooter {
            margin-left: 10%;
            margin-right: 8%;
            display: flex;
            color: white;

            p {
                text-align: left;
                font: normal normal normal 12px/14px Poppins;
                letter-spacing: 1.6px;
            }

            #redirectSubFooter {
                display: flex;

                .pipeFooter {
                    margin-left: 15px;
                    margin-right: 15px;
                }

                a {
                    color: white;
                    text-decoration: none;
                    cursor: pointer;
                    margin: 0;
                    text-align: left;
                    font: normal normal 400 13px Poppins;
                    letter-spacing: 1.6px;
                }
            }

            #altais {
                margin-left: auto;

            }
        }

        @media screen and (max-width: 768px) {
            #subFooter {
                display: flex;
                flex-direction: column;

                p {
                    margin-top: 15px;
                }

                #altais {
                    margin-left: 0;
                }
            }
        }
    }
}