@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


header {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.headerGA-navGA {
    display: flex;
    flex-direction: column;

    .headerGA {
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url(../../../../public/GA/images/fond-header.jpg);
        background-size: cover;
        background-repeat: no-repeat;


        .logoHeadGa {
            height: 80px;
            width: 80px;
            margin-right: 30px;
            margin-top: 10px;
            margin-bottom: 10px;
        }

        .button-headerGA {
            display: flex;
            flex-direction: column;

            .button-headerGA-top {
                display: flex;
                position: relative;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: inset 0px 3px 6px #0000001F;
                border-radius: 19px;
                height: 42px;
                width: 220px;

                .button-headerGA-left {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #075759 0% 0% no-repeat padding-box;
                    box-shadow: 0px 0px 6px #00000029;
                    border-radius: 15px;
                    margin: 4px;
                    width: 50%;
                    cursor: pointer;

                    &.active {
                        background: transparent !important;
                        /* Ajoutez !important pour forcer la priorité */
                        box-shadow: none;
                    }

                    p {
                        margin: 0px 12px;
                        text-align: left;
                        font: italic normal 400 14px Jost;
                        letter-spacing: 0px;
                        color: #FFFFFF;

                        &.left-text {
                            color: #075759;
                        }
                    }
                }

                .button-headerGA-right {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin: 4px;
                    width: 50%;
                    cursor: pointer;

                    &.active {
                        border-radius: 15px;
                        background: #075759;
                        box-shadow: 0px 0px 6px #00000029;
                    }

                    p {
                        margin: 0px 12px;
                        text-align: left;
                        font: italic normal 400 14px Jost;
                        letter-spacing: 0px;
                        color: #075759;

                        &.right-text {
                            color: #FFFFFF;
                        }
                    }
                }

                button {
                    position: absolute;
                    bottom: -20px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 20px;
                    height: 20px;
                    background-color: #FFFFFF;
                    border-radius: 50%;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;

                    img {
                        height: 12px;
                        width: 12px;
                    }
                }
            }

            .button-headerGA-bottom {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 15px;
                position: relative;

                select {
                    cursor: pointer;
                    background: #FFFFFF 0% 0% no-repeat padding-box;
                    box-shadow: 0px 3px 6px #00000029;
                    border-radius: 14px;
                    position: relative;
                    border: none;
                    text-shadow: 0px 3px 6px var(--unnamed-color-ffffff);
                    text-align: left;
                    font: italic normal normal 14px Jost;
                    letter-spacing: 0px;
                    color: #F94A4A;
                    text-shadow: 0px 3px 6px #FFFFFF;
                    margin-left: 10px;
                    margin-right: 22px;
                    margin-top: 0;
                    margin-bottom: 0;
                    padding: 0px 2px;

                    option {
                        color: #000000;
                    }
                }

                button {
                    position: absolute;
                    top: 50%;
                    right: 21%;
                    transform: translate(-50%, -50%);
                    box-shadow: 0px 3px 6px #00000029;
                    width: 22px;
                    height: 22px;
                    background-color: #F94A4A;
                    border-radius: 50%;
                    border: none;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: default;

                    img {
                        height: 8px;
                        width: 8px;
                    }
                }
            }
        }

        .form {
            margin-left: 70px;
            margin-right: 10px;

            form {
                display: flex;
                align-items: center;
                /* Aligner les éléments verticalement au centre */
                justify-content: center;
                height: 50px;
            }

            form input {
                height: 100%;
                width: 330px;
                border: none;
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                text-align: left;
                font: italic normal normal 16px Jost;
                letter-spacing: 0px;
                color: #075759;
                padding: 0 0 0 10px;
            }

            form input::placeholder {
                text-align: left;
                font: italic normal normal 16px Jost;
                letter-spacing: 0px;
                color: #075759;
                padding-left: 15px;
            }

            form button {
                height: 100%;
                cursor: pointer;
                border: none;
                background: #085A5C 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 0px 10px 10px 0px;
                padding: 0;
                margin: 0;
                width: 50px;

                img {
                    height: 15px;
                    width: 15px;
                }
            }


        }

        .contactHeader {
            text-align: center;
            margin-right: 30px;

            p {
                margin: 0;
            }

            .telHeader {
                font: normal normal 800 12px Poppins;
                letter-spacing: 0px;
                color: #085F61;
            }

            .headContact {
                font: normal normal 500 12px Poppins;
                letter-spacing: 0px;
                color: #085F61;
            }

            .horraireHead {
                font: italic normal 400 10px Poppins;
                letter-spacing: 0px;
                color: #085F61;
            }


        }

        .compteHeader-content {

            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .compteHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;

                .text-compte {
                    p {
                        margin: 0;
                        font: normal normal 500 14px Poppins;
                        letter-spacing: 0px;
                        color: #858383;
                        margin-right: 20px;

                        .name-account {
                            text-decoration: none;
                            font: normal normal 500 14px Poppins;
                            letter-spacing: 0px;
                            color: #858383;

                            i {
                                margin-right: 2px;
                            }
                        }


                        span {
                            font: normal normal bold 14px Jost;
                            letter-spacing: 0px;
                            color: #F94A4A;
                            margin-left: 5px;
                            margin-right: 10px;
                        }

                        .pipe {
                            font: normal normal 300 14px Jost;
                            letter-spacing: 0px;
                            color: #F94A4A;
                        }
                    }
                }

                .shopping-cart {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    background: #858383 0% 0% no-repeat padding-box;
                    border: 2px solid #FFFFFF;
                    border-radius: 15px;
                    width: 40px;
                    height: 15px;


                    p {
                        font: normal normal bold 10px Jost;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        margin-left: 8px;
                        margin-right: 10px;
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    button {
                        border: 2px solid #FFFFFF;
                        background: #F94A4A;
                        border-radius: 50%;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        width: 30px;

                        img {
                            height: 18px;
                            width: 18px;
                        }
                    }
                }
            }

            .franco-port {
                p {
                    margin: 0;
                    text-align: right;
                    font: normal normal 600 12px Jost;
                    letter-spacing: 0px;
                    color: #5c5c5c;
                }
            }
        }

    }
}


@media(max-width: 1250px) {
    .headerGA {
        display: flex;
        justify-content: space-around !important;

        .form {
            margin-left: 20px !important;
            margin-right: 20px !important;
        }

        .contactHeader {
            display: none;
        }

    }
}



@media(max-width: 1000px) {
    .headerGA {
        .form {
            margin-left: 20px !important;
            margin-right: 20px !important;
        }

        .contactHeader {
            display: none;
        }

        .ga-boutique-redirection {
            display: none;
        }

        .compteHeader-content {
            .compteHeader {
                .text-compte {
                    display: none;
                }
            }

            .franco-port {
                display: none;
            }
        }
    }
}



@media(max-width: 800px) {

    .headerGA {

        .logoHeadGa {
            height: 60px !important;
            width: 60px !important;
            margin-right: 15px !important;
            margin-top: 5x !important;
            margin-bottom: 5px !important;
        }


        .button-headerGA {

            .button-headerGA-top {
                height: 35px !important;
                width: 150px !important;

                .button-headerGA-left {
                    p {
                        margin: 0 !important;
                        text-align: center !important;
                        font: italic normal 400 10px Jost !important;
                    }
                }

                .button-headerGA-right {
                    p {
                        margin: 0 !important;
                        text-align: center !important;
                        font: italic normal 400 10px Jost !important;
                    }
                }

                button {
                    bottom: -18px !important;
                    width: 18px !important;
                    height: 18px !important;

                    img {
                        height: 10px !important;
                        width: 10px !important;
                    }
                }
            }

            .button-headerGA-bottom {
                button {
                    right: 8% !important;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .form {
            form {
                height: 40px !important;
            }

            form input {
                width: 150px !important;
                font: italic normal normal 14px Jost !important;
            }

            form input::placeholder {
                font: italic normal normal 14px Jost !important;
            }

            form button {
                width: 40px !important;

                img {
                    height: 13px !important;
                    width: 13px !important;
                }
            }
        }

        .shopping-cart {
            width: 30px !important;
            height: 12px !important;

            p {
                font: normal normal bold 8px Jost !important;
                margin-left: 8px;
                margin-right: 10px;
            }

            button {
                height: 25px !important;
                width: 25px !important;

                img {
                    height: 13px !important;
                    width: 13px !important;
                }
            }
        }
    }
}



@media(max-width: 500px) {

    .headerGA {

        .logoHeadGa {
            height: 50px !important;
            width: 50px !important;
            margin-right: 10px !important;
            margin-top: 5x !important;
            margin-bottom: 5px !important;
        }

        .button-headerGA {
            .button-headerGA-top {
                height: 30px !important;
                width: 120px !important;

                .button-headerGA-left {
                    margin: 2px !important;

                    p {
                        margin: 0 !important;
                        font: italic normal 400 8px Jost !important;

                    }
                }

                .button-headerGA-right {
                    margin: 2px !important;

                    p {
                        margin: 0 !important;
                        font: italic normal 400 8px Jost !important;

                    }
                }

                button {
                    bottom: -15px !important;
                    width: 15px !important;
                    height: 15px !important;

                    img {
                        height: 10px !important;
                        width: 10px !important;
                    }
                }
            }


            .button-headerGA-bottom {

                select {
                    border-radius: 14px;
                    font: italic normal normal 10px Jost !important;
                    margin-left: 5px;
                    margin-right: 16px;
                    margin-top: 0;
                    margin-bottom: 0;
                    padding: 0;
                }

                button {
                    top: 50%;
                    right: 13% !important;
                    width: 16px !important;
                    height: 16px !important;

                    img {
                        height: 6px !important;
                        width: 6px !important;
                    }
                }
            }
        }

        .form {
            margin-left: 0px !important;

            form {
                height: 35px !important;
            }

            form input {
                width: 80px !important;
                font: italic normal normal 10px Jost !important;
                padding-left: 2px !important;

            }

            form input::placeholder {
                font: italic normal normal 10px Jost !important;
                padding-left: 2px !important;
            }

            form button {
                width: 30px !important;

                img {
                    height: 10px !important;
                    width: 10px !important;
                }
            }
        }

        .shopping-cart {
            width: 30px !important;
            height: 12px !important;

            p {
                font: normal normal bold 8px Jost !important;
                margin-left: 8px;
                margin-right: 10px;
            }

            button {
                height: 25px !important;
                width: 25px !important;

                img {
                    height: 13px !important;
                    width: 13px !important;
                }
            }
        }
    }
}

.dfd-facet-title[dfd-value-key="categories"],
.dfd-facet-title[dfd-value-key="brand"],
.dfd-facet-title[dfd-value-key="best_price"],
.dfd-facet-title[dfd-value-key="price_platforme_adherant"],
.dfd-facet-title[dfd-value-key="price_direct_usine_adherant"],
.dfd-facet-title[dfd-value-key="price_direct_usine_conseile"],
.dfd-facet-title[dfd-value-key="price_platforme_conseile"],
.dfd-facet-characteristics {
    text-transform: uppercase;
    color: #096264;
}

.dfd-facet-characteristics {
    margin-top: 32px;
}

.ga-boutique-menu {
    display: flex;
    justify-content: center;
    height: 25px;
}