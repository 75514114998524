@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



header {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.headerCountry-navCountry {
    display: flex;
    flex-direction: column;
    max-height: 160px;

    .header {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        position: relative;
        overflow: hidden;

        .effet-1 {
            position: absolute;
            transform: translateX(10%);
            z-index: 0;
        }

        .effet-2 {
            position: absolute;
            left: -50px;
            bottom: -80px;
            z-index: 0;
        }

        .logo-country {
            z-index: 1;
        }

        .logo-header-country-size {
            height: 100px;
            width: 300px;
        }

        .form {
            margin-left: 70px;
            margin-right: 10px;
            z-index: 1;


            form {
                display: flex;
                align-items: center;
                /* Aligner les éléments verticalement au centre */
                justify-content: center;
                height: 50px;
            }

            form input {
                height: 100%;
                width: 330px;
                border: none;
                border-bottom-left-radius: 10px;
                border-top-left-radius: 10px;
                background: #FFFFFF 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                text-align: left;
                font: italic normal normal 16px Jost;
                letter-spacing: 0px;
                color: #064C37;
                padding: 0 0 0 10px;
            }

            form input::placeholder {
                text-align: left;
                font: italic normal normal 16px Jost;
                letter-spacing: 0px;
                color: #064C37;
                padding-left: 15px;
            }

            form button {
                height: 100%;
                cursor: pointer;
                border: none;
                background: #064C37 0% 0% no-repeat padding-box;
                box-shadow: 0px 3px 6px #00000029;
                border-radius: 0px 10px 10px 0px;
                padding: 0;
                margin: 0;
                width: 50px;

                img {
                    height: 15px;
                    width: 15px;
                }
            }


        }

        .contactHeader {
            text-align: center;
            margin-right: 30px;

            p {
                margin: 0;
            }

            .telHeader {
                font: normal normal 800 12px Poppins;
                letter-spacing: 0px;
                color: #064C37;
                text-decoration: none;
            }

            .headContact {
                font: normal normal 500 12px Poppins;
                letter-spacing: 0px;
                color: #064C37;
            }

            .horraireHead {
                font: italic normal 400 10px Poppins;
                letter-spacing: 0px;
                color: #064C37;
            }


        }

        .compteHeader-content {

            display: flex;
            flex-direction: column;
            align-items: flex-end;

            .compteHeader {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 10px;

                .text-compte {

                    a {
                        text-decoration: none;
                        margin: 0;
                        font: normal normal 500 14px Poppins;
                        letter-spacing: 0px;
                        color: #858383;
                    }
                    p {
                        i {
                            margin-right: 8px;
                        }

                        span {
                            font: normal normal bold 14px Jost;
                            letter-spacing: 0px;
                            color: #915833;
                            margin-right: 15px;
                        }

                        .pipe {
                            font: normal normal 300 14px Jost;
                            letter-spacing: 0px;
                            color: #915833;
                            margin: 0px;
                        }
                    }
                }

                .shopping-cart {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    background: #858383 0% 0% no-repeat padding-box;
                    border: 2px solid #FFFFFF;
                    border-radius: 15px;
                    width: 40px;
                    height: 15px;


                    p {
                        font: normal normal bold 10px Jost;
                        letter-spacing: 0px;
                        color: #FFFFFF;
                        margin-left: 8px;
                        margin-right: 10px;
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    button {
                        border: 2px solid #FFFFFF;
                        background: #915833;
                        border-radius: 50%;
                        cursor: pointer;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 30px;
                        width: 30px;

                        img {
                            height: 18px;
                            width: 18px;
                        }
                    }
                }
            }

            .franco-port {
                p {
                    margin: 0;
                    text-align: right;
                    font: normal normal 600 12px Jost;
                    letter-spacing: 0px;
                    color: #5c5c5c;
                }
            }
        }

    }
}



@media(max-width: 1400px) {
    .header {

        .effet-2 {
            left: -90px !important;
        }

    }
}

@media(max-width: 1250px) {
    .header {
        display: flex;
        justify-content: space-around !important;

        

        .effet-1 {
            transform: translateX(35%) !important;
        }

        .effet-2 {
            left: -120px !important;
        }

        .form {
            margin-left: 20px !important;
            margin-right: 20px !important;
        }

        .contactHeader {
            display: none;
        }

    }
}



@media(max-width: 1000px) {
    .header {

        .effet-1 {
            transform: translateX(55%) !important;
        }

        .logo-header-country-size {
            width: 250px !important;
        }

        .form {
            margin-left: 20px !important;
            margin-right: 20px !important;
        }

        .contactHeader {
            display: none;
        }

        .compteHeader-content {
            .compteHeader {
                .text-compte {
                    display: none;
                }
            }

            .franco-port {
                display: none;
            }
        }
    }
}



@media(max-width: 800px) {

    .header {
        .logo-header-country-size {
            width: 200px !important;
        }

        .form {
            form {
                height: 40px !important;
            }

            form input {
                width: 250px !important;
                font: italic normal normal 14px Jost !important;
            }

            form input::placeholder {
                font: italic normal normal 14px Jost !important;
            }

            form button {
                width: 40px !important;

                img {
                    height: 13px !important;
                    width: 13px !important;
                }
            }
        }

        .shopping-cart {
            width: 30px !important;
            height: 12px !important;

            p {
                font: normal normal bold 8px Jost !important;
                margin-left: 8px;
                margin-right: 10px;
            }

            button {
                height: 25px !important;
                width: 25px !important;

                img {
                    height: 13px !important;
                    width: 13px !important;
                }
            }
        }
    }
}



@media(max-width: 500px) {

    .header {

        .effet-2 {
            left: -125px !important;
        }

        .logo-header-country-size {
            width: 130px !important;
        }

        .form {
            margin-left: 0px !important;

            form {
                height: 35px !important;
            }

            form input {
                width: 80px !important;
                font: italic normal normal 10px Jost !important;
                padding-left: 2px !important;

            }

            form input::placeholder {
                font: italic normal normal 10px Jost !important;
                padding-left: 2px !important;
            }

            form button {
                width: 30px !important;

                img {
                    height: 10px !important;
                    width: 10px !important;
                }
            }
        }

        .shopping-cart {
            width: 30px !important;
            height: 12px !important;

            p {
                font: normal normal bold 8px Jost !important;
                margin-left: 8px;
                margin-right: 10px;
            }

            button {
                height: 25px !important;
                width: 25px !important;

                img {
                    height: 13px !important;
                    width: 13px !important;
                }
            }
        }
    }
}

.dfd-facet-title[dfd-value-key="category"],
.dfd-facet-title[dfd-value-key="categories"],
.dfd-facet-title[dfd-value-key="brand"],
.dfd-facet-title[dfd-value-key="best_price"],
.dfd-facet-characteristics {
    text-transform: uppercase;
    color: #096264;
}

.dfd-facet-characteristics {
    margin-top: 32px;
}