// #department_zone_form_departements {
//     appearance: none;
//     background-color: transparent !important;
//     background-image: none !important;
//     background-position: right 0.75rem center;
//     background-repeat: no-repeat;
//     background-size: 16px 12px;
//     border: none !important;
//     border-radius: none;
//     color: var(--text-color-dark);
//     display: block;
//     font-size: .875rem;
//     font-weight: 400;
//     line-height: 1.5;
//     padding: 0.375rem 2.25rem 0.375rem 0.75rem;
//     transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
//     width: 100%;
// };

.ts-wrapper.plugin-remove_button .item .remove {
    border-left: 1.5px solid var(--gray-200);
    background: rgb(219, 219, 219);
    border-radius: 7px;
}