@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body {
    padding: 0;
    margin: 0;
    width: 100%;
    font-family: 'Poppins', sans-serif;
}

.container {
    margin-left: 10%;
    margin-right: 10%;
}


.header-country {
    .title {
        background-image: url(../../../../public/Country/images/Groupe-414.jpg);
        background-position: center center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 450px;
        border-bottom: 3px solid white;
        border-top: 2px solid white;
        display: flex;

        .title-left {
            width: 50%;
        }

        .title-right {
            width: 50%;

            h1 {
                width: 80%;
                text-align: center;
                font: normal normal 900 52px Poppins;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
                background-color: #064C37;
                margin-left: 30px;
                margin-bottom: 10px;
            }

            .sub-title {
                display: flex;
                align-items: center;

                img {
                    height: 110px;
                    margin-right: 15px;
                    margin-left: 30px;
                }

                h2 {
                    margin: 0;
                    text-align: left;
                    font: normal normal 900 32px Poppins;
                    letter-spacing: 0px;
                    color: #915833;
                    text-transform: uppercase;
                }
            }

            .logo-country-oblique {
                img {
                    height: 80px;
                    margin-top: 15px;
                    margin-left: 40px;
                }
            }
        }


    }

    .presentation {
        background-image: url(../../../../public/Country/images/reassurance.jpg);
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        height: 350px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        margin-bottom: 60px;
        position: relative;
        min-height: 350px;
        height: 100%;

        .bodure-verte-1 {
            position: absolute;
            bottom: -129px;
            left: -100px;
            z-index: 10;

            img {
                width: 300px;
                height: 550px;
            }
        }

        .bodure-verte-2 {
            position: absolute;
            bottom: -15px;
            right: 0px;

            img {
                width: 60px;
                height: 155px;
            }

        }

        .presentation-title {

            h2 {
                text-align: center;
                font: normal normal 800 26px Poppins;
                letter-spacing: 0px;
                color: #FFFFFF;
                text-transform: uppercase;
            }
        }

        .presentation-icon {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 80px;
            width: 100%;

            .presentation-responsive {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: row;
                gap: 80px;
            }

            .presentation-icon-content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                .circle {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80px;
                    height: 80px;
                    background-color: #33AE89;
                    border-radius: 50%;
                }

                img {
                    width: 35px;
                    height: 35px;
                }

                p {
                    text-align: center;
                    font: normal normal normal 22px Poppins;
                    letter-spacing: 0px;
                    color: #FFFFFF;
                    text-shadow: 0px 3px 6px #00000029;
                }
            }
        }
    }



    @media (max-width: 1050px) {
        .presentation {
            height: 100%;

            .presentation-icon {
                flex-direction: column;
                display: flex;
                align-items: center;
                justify-content: space-around;


                .presentation-responsive {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    grid-gap: 10px 30px;
                }

                .presentation-icon-content {

                    .circle {
                        width: 65px;
                        height: 65px;
                    }

                    img {
                        width: 30px;
                        height: 30px;
                    }

                    p {
                        font: normal normal normal 18px Poppins;
                    }
                }
            }

            .presentation-title {

                h2 {
                    font: normal normal 800 18px Poppins;
                }
            }
        }
    }

    @media (max-width: 800px) {

        .bodure-verte-1 {
            display: none;
        }

        .bodure-verte-2 {
            display: none;
        }
    }
}

.title-principal {
    margin-bottom: 10px;

    p {
        position: relative;
        text-align: center;
        color: #064C37;
        font-size: 32px;
        font-family: 'Jost', sans-serif;
        font-weight: 900;
        text-transform: uppercase;
        margin: 0;
    }
}


@media screen and (max-width: 768px) {


    .container {
        margin-left: 5%;
        margin-right: 5%;
    }

    .title-principal {
        p {
            font-size: 30px;
        }
    }
}


@media screen and (max-width: 540px) {
    .container {
        margin-left: 5%;
        margin-right: 5%;
    }

    .title-principal {
        p {
            font-size: 28px;
        }
    }
}

.divBtnVoirPlus {
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;

    .btnVoirPlus {
        margin-left: auto;
        padding: 7px 10px;
        color: #064C37;
        border: 1px #064C37 solid;
        border-radius: 5px;
        text-decoration: none;

        i {
            margin-right: 10px;
        }
    }

    .btnVoirPlus:hover {
        background-color: #064C37;
        color: white;
        transition: 0.3s ease;
    }
}

#sectionPromo {
    margin-bottom: 60px;

}

.promotion {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 12px;
    margin-bottom: 60px;

    .promotion1 {
        img {
            object-fit: contain;
            max-width: 100%;
            height: 100%;
            border-radius: 50px;
        }
    }

    .promotion-little {
        display: flex;
        gap: 12px;

        .promotion2 {
            margin: 0 auto;
            /* Centrer horizontalement */

            img {
                object-fit: contain;
                max-width: 100%;
                height: 100%;
                border-radius: 50px;
            }
        }
    }


}

@media screen and (max-width: 800px) {
    .promotion {
        flex-direction: column;
        align-items: center;

        .promotion1,
        .promotion2 {
            width: 100%;
            margin: 0 auto;
            display: flex;
            justify-content: contain;

            img {
                object-fit: contain;
                max-width: 100%;
                height: 100%;
                border-radius: 50px;
            }
        }
    }
}

.novelties {
    margin-top: 80px;
}

.best-sellers {
    margin-bottom: 60px;
}


// section incontournable 

.sectionIncontournable {
    background-image: url(../../../../public/GA/images/geometrical_shapes.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    #title {
        color: var(--unnamed-color-085f61);
        text-align: center;
        font: normal normal 900 28px Poppins;
        letter-spacing: 0px;
        color: #064C37;
        text-transform: uppercase;
        opacity: 1;
        padding-top: 58px;
    }
}


// section Qui sommes-nous  

.section-qui-somme-nous {
    background-image: url(../../../../public/Country/images/background-ground.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    .section-qui-somme-nous-content {
        display: flex;
        justify-content: space-around;
        padding: 40px 120px;
        gap: 60px;
        position: relative;

        .content {
            color: #FFFFFF;
            font-family: 'Poppins';
            width: 50%;
        }

        .button {
            display: flex;
            justify-content: center;
            position: absolute;
            bottom: -15px;
            left: 50%;
            transform: translateX(-50%);
            width: 250px;


            button {
                background-color: black;
                color: #FFFFFF;
                padding: 5px 15px;
                border: 1px solid #FFFFFF;
                text-align: center;
                font: normal normal 400 14px Poppins;
                letter-spacing: 0px;
                cursor: pointer;

                i {
                    color: #FFFFFF;
                }
            }

            button:hover {
                background-color: #064C37;
            }
        }

        h3 {
            color: #FFFFFF;
            text-align: left;
            font: normal normal 900 24px Poppins;
            letter-spacing: 0px;
            text-transform: uppercase;
        }

        p {
            text-align: left;
            font: normal normal 400 13px Poppins;
            letter-spacing: 0px;
            color: #FFFFFF;
            margin: 20px 0px;
        }

        .italic {
            font: italic normal 400 13px Poppins;
        }

        span {
            font: normal normal 700 13px Poppins;
        }

    }
}

@media (max-width: 800px) {
    .section-qui-somme-nous-content {
        flex-direction: column;
        padding: 20px 40px !important;
        gap: 10px !important;

        .content {
            width: auto !important;
        }
    }
}